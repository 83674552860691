:root {
    --main-bg-color: #EEEEEE !important;
    --main-font-color: #012A4A !important;
    --secondary-bg-color: #063C68 !important;
    --secondary-font-color: #EEEEEE !important;
    --button-bg-color: #012A4A !important;
    --button-hover-bg-color: #063C68 !important;
    --button-hover-font-color: #ffffff !important;
}

html {
    background: var(--main-bg-color) !important;
    font-family: Arial, Helvetica, sans-serif !important;
    margin: none !important;
    padding: none !important;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height to vertically center */
    background-color: #f8f9fa;
    /* Optional: light background */
}

.highlight {
    color: #91080A !important;
}

.tlogin {
    color: var(--button-hover-font-color);
    font-family: monospace;
    padding-top: 20px;
}

Time .time-selector {
    margin-bottom: 20px;
}

.time-selector label {
    margin-right: 10px;
    font-weight: bold;
    color: #063C68 !important;
}

.time-selector input[type="time"] {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}



.canvas-overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: all;
    /* Enable interaction */
    z-index: 1;
    width: 1200px;
    height: 560px;
}


/* LIVEINTERFACE */
.custom-container {
    background-color: var(--main-bg-color) !important;
}

.caminfo {
    padding-top: 5px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--main-font-color) !important;
}

.caminfo p {
    color: var(--main-font-color) !important;
    float: left !important;
    height: 10px !important;
    text-decoration: none !important;
    font-size: 20px !important;
    padding-left: 35px !important;
    padding-right: 240px !important;

}

.video-frame {
    margin-top: 40px !important;
    width: 1200px !important;
    height: 560px !important;
    margin-left: 4px !important;
    border: 4px solid var(--button-bg-color) !important;
    object-fit: fill !important;
    position: relative;
    max-width: 1200px;
    height: 560px;
    z-index: 1;
}

svg {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 2 !important;
    pointer-events: all !important;
    /* Allow clicks to pass through */
}


.trafficinfo {
    color: var(--main-font-color) !important;
    float: right !important;
    margin-top: 15px;
    margin-right: 25px;
}

.tinfo {
    text-align: center !important;
    width: 277px !important;
    padding-top: 16px;

}

.tinfo h4 {
    font-size: 15px !important;
    font-weight: bold !important;
}

.tcinfo1 {
    border-radius: 10px 10px !important;
    font-size: 20px !important;
    height: 46px !important;
    padding-top: 6px !important;
    background-color: var(--secondary-bg-color) !important;
    color: var(--secondary-font-color) !important;
}

.tcinfo2 {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    border-radius: 10px 10px !important;
    font-size: 20px !important;
    height: 190px !important;
    background-color: var(--secondary-bg-color) !important;
    color: var(--secondary-font-color) !important;
}

.tcinfo2 p {
    flex: 1 1 auto !important;
    margin: 5px !important;
    text-align: center !important;
}

.buttons {
    float: left;
    display: flex;
    justify-content: center;
    /* Center all buttons horizontally */
    align-items: center;
    /* Align them vertically in case the height differs */
    gap: 20px;
    /* Spacing between buttons */
}

.buttons button {
    border-radius: 10px 10px !important;
    background-color: var(--button-bg-color) !important;
    color: var(--secondary-font-color) !important;
    border: none !important;
    padding: 15px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 16px !important;
    margin: 20px 45px !important;
    cursor: pointer !important;
    width: 200px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.buttons button:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}


/* LOGIN */
.custom-login {
    margin-left: 450px !important;
    margin-right: 450px !important;
    margin-top: 100px !important;
    width: 650px !important;
    height: 400px !important;
    text-align: center !important;
    background-color: var(--button-hover-bg-color) !important;
    backdrop-filter: blur(10px) !important;
    color: var(--secondary-font-color) !important;
    border-radius: 20px 20px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.custom-login input {
    background-color: var(--button-bg-color) !important;
    margin-top: 50px !important;
    border: none !important;
    border-radius: 10px 10px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: background-color 0.3s ease, box-shadow 0.3s ease !important;
    color: var(--secondary-font-color) !important;
}

.custom-login input:hover {
    background-color: var(--button-hover-bg-color) !important;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3) !important;

}

.custom-login input:hover::placeholder {
    color: var(--button-hover-font-color) !important;
}


.custom-login input::placeholder {
    color: var(--button-hover-font-color) !important;
}

.custom-login button {
    margin-top: 50px !important;
    background-color: var(--button-bg-color) !important;
    border-radius: 10px 10px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    border: solid 1px var(--button-bg-color) !important;
    width: 150px !important;
    height: 50px !important;
    font-size: 20px !important;
    color: var(--button-hover-font-color) !important;
    font-weight: 500 !important;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease !important;
}

.custom-login button:hover {
    background-color: var(--button-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.04) !important;
}

/* LIVEINTERFACETWO*/
.custom-containertwo {
    background-color: var(--main-bg-color) !important;
}

.caminfotwo {
    padding-top: 5px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--main-font-color) !important;
}

.caminfotwo p {
    color: var(--main-font-color) !important;
    float: left !important;
    height: 10px !important;
    text-decoration: none !important;
    font-size: 20px !important;
    padding-left: 35px !important;
    padding-right: 240px !important;
}

.buttonstwo {
    position: relative;
    z-index: 3;
    /* Ensure buttons are on top */
}


.trafficinfotwo {
    color: var(--main-font-color) !important;
    float: right !important;
    margin-top: 5px;
    margin-right: 25px;
}

.tinfotwo {
    text-align: center !important;
    width: 277px !important;
    padding-top: 10px;

}

.tinfotwo h4 {
    font-size: 15px !important;
    font-weight: bold !important;
}

.tcinfo1two {
    border-radius: 10px 10px !important;
    font-size: 16px !important;
    height: 70px !important;
    background-color: var(--secondary-bg-color) !important;
    color: var(--secondary-font-color) !important;
}

.tcinfo1two-custom {
    border-radius: 10px 10px !important;
    font-size: 16px !important;
    height: 35px !important;
    padding-top: 5px;
    background-color: var(--secondary-bg-color) !important;
    color: var(--secondary-font-color) !important;
}

.tcinfo2two {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    border-radius: 10px 10px !important;
    font-size: 15px !important;
    height: 80px !important;
    background-color: var(--secondary-bg-color) !important;
    color: var(--secondary-font-color) !important;
}

.tcinfo2two p {
    flex: 1 1 auto !important;
    margin: 5px !important;
    text-align: center !important;
}

.buttonstwo {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.buttonstwo button {
    border-radius: 10px 10px !important;
    background-color: var(--button-bg-color) !important;
    color: var(--secondary-font-color) !important;
    border: none !important;
    padding: 15px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 16px !important;
    margin: 20px 45px !important;
    cursor: pointer !important;
    width: 200px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.buttonstwo button:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}


/* HISTORY */
.info-h {
    margin-left: 40px;
    padding-top: 20px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--secondary-bg-color) !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}

.table-d {
    margin-left: 40px;
    margin-top: 60px !important;
    width: 1160px !important;
    height: 610px !important;
    background-color: white !important;
    border-radius: 15px !important;
}

.tablebody {
    background-color: white !important;
    text-align: center !important;
}

.theader {
    background-color: white !important;
    text-align: center !important;
}

.info {
    margin-left: 40px;
    padding-top: 20px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--main-font-color) !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}

.info-data {
    color: var(--main-font-color) !important;
    float: right !important;
    margin-right: 25px;
}



.history-button {
    text-align: center !important;
    width: 277px !important;
    padding-top: 5px;
}


.all-button:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}

.all-button {
    border-radius: 10px 10px !important;
    background-color: var(--button-bg-color) !important;
    color: var(--secondary-font-color) !important;
    border: none !important;
    padding: 15px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 20px !important;
    cursor: pointer !important;
    margin-top: 38px;
    /* margin-left: 5px !important; */
    width: 250px !important;
    height: 70px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.his-button {
    text-align: center !important;
    width: 277px !important;
    padding-top: 22px;
}

.current-btn {
    background-color: #91080A !important;
    color: var(--button-hover-font-color) !important;
}

.table-and-buttons {
    display: flex;
    justify-content: space-between;
    /* Optional: Adds space between the table and buttons */
}

/*Map*/
.info-map {
    margin-left: 40px;
    padding-top: 20px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--secondary-bg-color) !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}

.maps-button {
    text-align: center !important;
    width: 277px !important;
    padding-top: 5px;
}

.map-body {
    display: flex;
    justify-content: space-between;
}

.mapstyle {
    width: 1200px;
    height: 620px;
}

.map {
    margin-left: 40px;
    margin-top: 60px !important;
    background-color: var(--secondary-bg-color) !important;
    border: 5px solid var(--secondary-bg-color) !important;
}





.map-buttons {
    border-radius: 10px 10px !important;
    background-color: var(--button-bg-color) !important;
    color: var(--secondary-font-color) !important;
    border: none !important;
    padding: 10px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 20px !important;
    cursor: pointer !important;
    margin-top: 13px;
    margin-left: 15px !important;
    width: 250px !important;
    height: 55px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.map-buttons:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}

.congestion-info {
    text-align: left !important;
    margin-right: 20px;
    margin-left: 20px;
    width: 250px !important;
    margin-top: 20px !important;
    background-color: var(--secondary-bg-color);
    border-radius: 10px 10px !important;
    color: var(--secondary-font-color);

}

.conlevel {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 55px;
    font-size: 20px;
    margin-left: 5px;
}

.conlevel-forecast {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 45px;
    font-size: 20px;
    margin-left: 5px;
}

.twobuttons {
    margin-top: 10px !important;
}

.circle {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;

}

.gridlock {
    background-color: #AB0003;
    float: right !important;
}

.heavy {
    background-color: #FF1519;
    float: right !important;
}

.moderate {
    background-color: #FFA800;
    float: right !important;
}

.low {
    background-color: #FFE500;
    float: right !important;
}

.free-flow {
    background-color: #05FF00;
    float: right !important;
}




/*MapDraw*/

.scrollable-list {
    max-height: 200px;
    /* Set the desired height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.selected-row {
    font-weight: bolder !important;
}

.info-mapdraw {
    margin-left: 40px;
    padding-top: 20px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--secondary-bg-color) !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}

.maps-buttondraw {
    text-align: center !important;
    width: 277px !important;
    padding-top: 40px;
}

.map-bodydraw {
    display: flex;
    justify-content: space-between;
}

.mapstyle {
    width: 1200px;
    height: 620px;
}

.mapdraw {
    margin-left: 40px;
    margin-top: 60px !important;
    background-color: var(--secondary-bg-color) !important;
    border: 5px solid var(--secondary-bg-color) !important;
}

.custom-modal-width {
    max-width: 90% !important;
}

.custom-modal-body {
    max-height: 490px;
    overflow-y: auto;

}


.title-column {
    width: 120px;
    font-size: large !important;
    font-weight: bold !important;
}

.text-column {
    font-size: medium !important;

}

.date-column {
    width: 100px;
}

.delete-column {
    width: 50px;
}

.custom-table .title-column {
    width: 20%;
  }
  
  .custom-table .text-column {
    width: 70%;
  }
  
  .custom-table .date-column {
    width: 9%;
  }
  
  .custom-table .delete-column {
    width: 7%;
  }

.map-buttonsdraw {
    border-radius: 10px 10px !important;
    background-color: var(--button-bg-color) !important;
    color: var(--secondary-font-color) !important;
    border: none !important;
    padding: 15px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 20px !important;
    cursor: pointer !important;
    margin-top: 15px;
    margin-left: 15px !important;
    width: 250px !important;
    height: 70px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.map-buttonsdraw:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}

.tools-info {
    text-align: left !important;
    margin-right: 20px;
    margin-left: 20px;
    width: 250px !important;
    margin-top: 20px !important;
    background-color: var(--secondary-bg-color);
    border-radius: 10px 10px !important;
    color: var(--secondary-font-color);

}

.tools-info Button {
    color: black;
    background-color: #EEEEEE;
    border: none;
}

.conleveldraw {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 10px !important;
    margin-left: 5px;
    height: 40px !important;
    width: 70px !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.consleveldraw {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 10px !important;
    margin-left: 5px;
    height: 40px !important;
    width: 70px !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.hazardleveldraw {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 5px;
    font-size: 10px !important;
    margin-left: 5px;
    height: 40px !important;
    width: 56px !important;
    text-align: center !important;
    font-weight: 600 !important;
}

.colleveldraw {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 10px !important;
    margin-left: 5px;
    height: 40px !important;
    width: 56px !important;
    text-align: center !important;


}

.colb {
    font-weight: 600 !important;
    background-color: blue !important;
    border: none !important;
    color: #EEEEEE !important;
}

.colg {
    font-weight: 600 !important;
    background-color: green !important;
    border: none !important;
    color: #EEEEEE !important;
}

.colr {
    font-weight: 600 !important;
    background-color: red !important;
    border: none !important;
    color: #EEEEEE !important;
}

.coly {
    font-weight: 600 !important;
    background-color: yellow !important;
    border: none !important;
    color: #EEEEEE !important;
}

.opleveldraw {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 10px;
    font-size: 13px !important;
    margin-left: 3px;
    margin-right: 4px;
    height: 40px !important;
    width: 55px !important;
    text-align: center !important;
    font-weight: bold !important;
}

.opleveldraw.erase-on {
    border: 2px solid rgb(255, 255, 255);
    /* Example color: red */
    color: #fff;
    /* Example text color: white */
    font-size: 10px !important;
}

.mapdraw-cat {
    font-size: 20px !important;
    border-bottom: 2px solid white !important;
    padding-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}


.twobuttonsdraw {
    margin-top: 10px !important;
}



.entrance-only {
    background-color: #FF1519;
    float: right !important;
}

.vehicle-route {
    background-color: #FFA800;
    float: right !important;
}

.road-cons {
    background-color: #FFE500;
    float: right !important;
}

.hazard {
    background-color: #05FF00;
    float: right !important;
}



/* Command Center History */
.info-hc {
    margin-left: 40px;
    padding-top: 20px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--secondary-bg-color) !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}


.table-dc {
    margin-left: 40px;
    margin-top: 60px !important;
    width: 1160px !important;
    height: 610px !important;
    background-color: white !important;
    border-radius: 15px !important;
}

.tablebodyc {
    background-color: white !important;
    text-align: center !important;
}

tbody {
    display: block !important;
    height: 505px !important;
    overflow: auto !important;
}

thead,
tbody tr {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important;
}

.theaderc {
    background-color: white !important;
    text-align: center !important;
}

.infoc {
    margin-left: 40px;
    padding-top: 20px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--main-font-color) !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}

.info-datac {
    color: var(--main-font-color) !important;
    float: right !important;
    margin-right: 25px;
}



.history-buttonc {
    text-align: center !important;
    width: 277px !important;
    padding-top: 5px;
}


.all-buttonc:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}

.all-buttonc {
    border-radius: 10px 10px !important;
    background-color: var(--button-bg-color) !important;
    color: var(--secondary-font-color) !important;
    border: none !important;
    padding: 15px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 20px !important;
    cursor: pointer !important;
    margin-top: 38px;
    /* margin-left: 5px !important; */
    width: 250px !important;
    height: 70px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.his-buttonc {
    text-align: center !important;
    width: 277px !important;
    padding-top: 22px;
}

.current-btnc {
    background-color: #91080A !important;
    color: var(--button-hover-font-color) !important;
}

.table-and-buttons {
    display: flex;
    justify-content: space-between;
    /* Optional: Adds space between the table and buttons */
}

/* .func-container {} */

.func-button {
    width: 200px;
    height: 50px;
    border-radius: 20px 20px;
    margin: 0 45px;
    /* adds horizontal margin */
    background-color: var(--button-bg-color) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
    color: var(--secondary-font-color) !important;
    outline: none !important;
    border: 1px solid var(--button-bg-color) !important;
}

.func-buttongreen {
    background-color: green !important;
    width: 200px;
    height: 50px;
    border-radius: 20px 20px;
    margin: 0 45px;
    /* adds horizontal margin */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
    color: var(--secondary-font-color) !important;
    outline: none !important;
    border: 1px solid green !important;
}

.current-func {
    width: 200px;
    height: 50px;
    border-radius: 20px 20px;
    margin: 0 20px;
    /* adds horizontal margin */
    background-color: #91080A !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
    color: var(--secondary-font-color) !important;
    outline: none !important;
    border: 1px solid #91080A !important;
}


.current-func:hover {
    background-color: #91080A !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}





.func-button:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}

tfoot {
    background-color: #ffffff;
    text-align: center;
}

tfoot .func-container {
    padding: 1px 0;
    display: flex;
    /* aligns the buttons in a row */
    justify-content: center;
    /* centers the buttons */
}

.table-dc tbody tr:hover {
    cursor: pointer !important;
}

.selected {
    font-weight: 500 !important;
    /* Makes the text bold */
    color: #91080A !important;
    /* Changes the text color */
}

.data-button {
    width: 140px;
    height: 50px;
    border-radius: 20px 20px;
    margin: 0 5px;
    /* adds horizontal margin */
    background-color: var(--button-bg-color) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
    color: var(--secondary-font-color) !important;
    outline: none !important;
    border: 1px solid var(--button-bg-color) !important;

}

.selected-history {
    background-color: #91080A !important;
    color: white !important;
}


/* .Toastify__toast--error {
    background: #f44336;
    color: white;
  }
  
  .Toastify__toast--success {
    background: #4caf50;
    color: white;
  }
  
  .Toastify__toast--info {
    background: #2196f3;
    color: white;
  }
  
  .Toastify__toast--warning {
    background: #ff9800;
    color: white;
  }
  
  .Toastify__toast-body {
    font-family: Arial, sans-serif;
    font-size: 16px;
  }
  
  .Toastify__progress-bar {
    background: #ffffff;
  } */


/* Multiple Cam css */
.wrapper {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-auto-rows: minmax(50px, auto);
    grid-gap: 1px;

}

/* 
.cam-1,
.cam-2,
.cam-3,
.cam-4 {
    background-color: grey;
    text-align: center;
    padding: 200px 20px 30px 20px;
    color: white;
    margin: 10px;
    cursor: pointer;
}

.cam-1 {
    grid-column: 1 / 6 !important;
    grid-row: 2 / 4 !important;
}

.cam-2 {
    grid-column: 6/ 11 !important;
    grid-row: 2 / 4 !important;
}

.cam-3 {
    grid-column: 1 / 6 !important;
    grid-row: 4 / 5 !important;
}

.cam-4 {
    grid-column: 6 / 11 !important;
    grid-row: 4 / 5 !important;
} */

.info-datacs {
    grid-column: 11 / 12 !important;
    grid-row: 2 / 11 !important;

}

.his-buttons {
    padding-left: 10px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: 11 / 12 !important;
    grid-row: 1 / 5 !important;
    float: right !important;

}

.info-hc {
    margin-left: 40px;
    padding-top: 20px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--secondary-bg-color) !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}

.high {
    color: #91080A !important;
}



/* CCTV CAMERA */
/* .his-button2 {
    text-align: center !important;
    width: 277px !important;
    padding-top: 22px; */
/* } */

.all-buttond {
    border-radius: 10px 10px !important;
    background-color: var(--button-bg-color) !important;
    color: var(--secondary-font-color) !important;
    border: none !important;
    padding: 15px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 20px !important;
    cursor: pointer !important;
    margin-top: 20px;
    width: 250px !important;
    height: 70px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.his-buttons2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: right !important;
}

.add-remove-container {
    margin-top: 38px;
    display: flex;
    gap: 5px;
    /* Gap between buttons */
}

.add-button,
.remove-button {
    margin-left: 20px;
    border-radius: 10px 10px !important;
    background-color: var(--button-bg-color) !important;
    border: none !important;
    padding: 10px 20px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 20px !important;
    cursor: pointer !important;
    margin-top: 30px;
    width: 120px !important;
    height: 50px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
}

.add-button,
.remove-button {
    color: var(--secondary-font-color) !important;
}

.remove-button:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    background: red !important;
    transform: scale(1.05) !important;
}

.add-button:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    background: green !important;
    transform: scale(1.05) !important;
}

.all-buttond:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}

.current-btnc {
    background-color: #91080A !important;
    color: var(--button-hover-font-color) !important;
}





.image-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    /* Ensure overlay is on top */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Container for the image and buttons */
.image-viewer-container {
    position: relative;
}

/* Styling the image */
.image-viewer-image {
    max-width: 90vw;
    max-height: 90vh;
    z-index: 1;
}

/* Styling the buttons */
.image-viewer-close,
.image-viewer-prev,
.image-viewer-dl,
.image-viewer-next {
    position: absolute;
    padding: 15px;
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    z-index: 2;
}

/* Hover effect for buttons */
.image-viewer-close:hover,
.image-viewer-prev:hover,
.image-viewer-dl:hover,
.image-viewer-next:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

/* Positioning the Close button */
.image-viewer-close {
    top: 20px;
    right: -50px;
}

.image-viewer-dl {
    top: 30px;
    left: -50px;
}


/* Positioning the Previous button */
.image-viewer-prev {
    top: 50%;
    left: -50px;
    transform: translateY(-50%);
}

/* Positioning the Next button */
.image-viewer-next {
    top: 50%;
    right: -50px;
    transform: translateY(-50%);
}



/*added*/
.extra-button {
    width: 130px;
    height: 50px;
    border-radius: 20px 20px;
    margin-left: 10px;

    /* adds horizontal margin */
    background-color: var(--button-bg-color) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
    color: var(--secondary-font-color) !important;
    outline: none !important;
    border: 1px solid var(--button-bg-color) !important;
}

.analytic-button {
    background-color: green !important;
    border: 1px solid lightgreen !important;
    margin-left: 20px;
    font-size: 15px !important;
}


.extra-button:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}

.linechart {
    margin-top: 20px !important;
}

.func-chart {
    margin-top: 60px !important;
    margin-left: 30px !important;
}

.selectedButton {
    width: 130px;
    height: 50px;
    border-radius: 20px 20px;
    margin-left: 10px;

    /* adds horizontal margin */
    background-color: #91080A !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
    color: var(--secondary-font-color) !important;
    outline: none !important;
    border: 1px solid var(--button-bg-color) !important;
}





/* Overlay for the image viewer */
.image-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    /* Ensure overlay is on top */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Container for the image and buttons */
.image-viewer-container {
    position: relative;
}

/* Styling the image */
.image-viewer-image {
    max-width: 90vw;
    max-height: 90vh;
    z-index: 1;
}

/* Styling the buttons */
.image-viewer-close,
.image-viewer-prev,
.image-viewer-dl,
.image-viewer-next,
.image-viewer-ai,
.image-viewer-an {
    position: absolute;
    padding: 15px;
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    z-index: 2;
}

/* Hover effect for buttons */
.image-viewer-close:hover,
.image-viewer-prev:hover,
.image-viewer-dl:hover,
.image-viewer-next:hover,
.image-viewer-ai:hover,
.image-viewer-an:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

/* Positioning the Close button */
.image-viewer-close {
    top: 30px;
    right: -50px;
}

.image-viewer-dl {
    top: 30px;
    left: -50px;
}

.image-viewer-an {
    top: 90px;
    left: -50px;
}

.image-viewer-ai {
    top: 150px;
    left: -50px;
}



/* Positioning the Previous button */
.image-viewer-prev {
    top: 50%;
    left: -50px;
    transform: translateY(-50%);
}

/* Positioning the Next button */
.image-viewer-next {
    top: 50%;
    right: -50px;
    transform: translateY(-50%);
}



/*added*/
.extra-button {
    width: 130px;
    height: 50px;
    border-radius: 20px 20px;
    margin-left: 10px;

    /* adds horizontal margin */
    background-color: var(--button-bg-color) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
    color: var(--secondary-font-color) !important;
    outline: none !important;
    border: 1px solid var(--button-bg-color) !important;
}

.analytic-button {
    background-color: green !important;
    border: 1px solid lightgreen !important;
    margin-left: 20px;
    font-size: 15px !important;
}


.extra-button:hover {
    background-color: var(--button-hover-bg-color) !important;
    color: var(--button-hover-font-color) !important;
    transform: scale(1.05) !important;
}

.linechart {
    margin-top: 20px !important;
}

.func-chart {
    margin-top: 60px !important;
    margin-left: 30px !important;
}

.selectedButton {
    width: 130px;
    height: 50px;
    border-radius: 20px 20px;
    margin-left: 10px;

    /* adds horizontal margin */
    background-color: #91080A !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: all 0.3s ease !important;
    color: var(--secondary-font-color) !important;
    outline: none !important;
    border: 1px solid var(--button-bg-color) !important;
}


/* Chart Overlay Styles */
.chart-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    /* Center content vertically and horizontally */
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
    overflow: hidden;
}

.chart-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    height: 50px;
    width: 50px;
}

.chart-navigation {
    /* Remove absolute positioning to place it within the flex flow */
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    /* Space between buttons and chart */
}

.chart-navigation button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #ffffffaa;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.chart-navigation button:hover {
    background-color: #ffffff;
}

.chart-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* Set a max-width and max-height to prevent the chart from being too large */
    max-width: 1200px;
    max-height: 80%;
}

.chart-wrapper {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    overflow: auto;
}

@media (min-width: 768px) {
    .chart-wrapper {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .chart-wrapper {
        width: 100%;
    }
}

/* Custom Tooltip Styles */
.custom-tooltip {
    border-radius: 4px;
}

.selectedblue {
    background-color: #007bff !important;
    /* Choose a clear color */
    border-color: #007bff !important;
}


.media-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Prevent scrollbars */
}

.media-element {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures media fills the container */
    display: block;
  }



.fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.7;
    z-index: 10; /* Ensure button is above media */
}


  .fullscreen-button:hover {
    opacity: 1;
  }
  

.cameraname{
    font-size: 15px;
    color: #000;
}

.cameralocation{
    font-size: 12px;
    color: #000;
}



.cam-container {
    background-color: #012A4A;
    border: 1px solid #19054b;
    margin: 50px auto 0;
    display: grid;
    padding: 5px;
    grid-template-rows: repeat(3, 200px);
    /* Maximum of 3 rows, each 200px tall */
    grid-template-columns: repeat(4, 1fr);
    /* Maximum of 4 columns, each taking an equal fraction of the available width */
    gap: 2px;
    row-gap: 50px;
    max-height: 90vh; /* Adjust the value as needed */
    overflow-y: auto;
    width: 1240px; /* Fix container width */
}

.camera {
    width: 300px;
    height: 200px;
    border: 1px solid #0b041d;
    background-color: #dcdcdc;
    object-fit: cover;
    object-position: bottom;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}
.camera video,
.camera iframe {
    width: 300px !important;
    height: 200px !important;
}

.camera h5 {
    margin: 0 0 5px 0;
}

.camera p {
    margin: 0 0 5px 0;
}


.info-hct {
    margin-left: 10px;
    padding-top: 10px;
    overflow: hidden !important;
    text-align: center !important;
    position: absolute !important;
    color: var(--secondary-bg-color) !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}

.no-underline {
  text-decoration: none !important;
}


.selected1 {
    font-weight: 500 !important;
    /* Makes the text bold */
    color: #AB0003 !important;
    /* Changes the text color */
}

.selected2 {
    font-weight: 500 !important;
    /* Makes the text bold */
    color: #FF1519 !important;
    /* Changes the text color */
}

.selected3 {
    font-weight: 500 !important;
    /* Makes the text bold */
    color: #FFA800 !important;
    /* Changes the text color */
}

.selected4 {
    font-weight: 500 !important;
    /* Makes the text bold */
    color: #FFE500 !important;
    /* Changes the text color */
}
.selected5 {
    font-weight: 500 !important;
    /* Makes the text bold */
    color: #05FF00 !important;
    /* Changes the text color */
}

.conlevel:hover {
    cursor: pointer !important;
    font-weight: 500 !important;
}